import React from 'react'

import {
  BannerWrapper,
  BannerBackground,
  BannerInner,
  BannerTitle,
  BannerSubtitle,
} from 'components/organisms/Banner/BannerSimple.style'
import Container from 'components/atoms/Container'

import { IGatsbyImageData } from 'gatsby-plugin-image'

type Props = {
  title: string
  subtitle?: string
  background: IGatsbyImageData
  high?: boolean
  align?: string
}

const BannerSimple: React.FC<Props> = ({
  title,
  subtitle,
  background,
  high,
  align,
}) => {
  return (
    <BannerWrapper high={high} align={align}>
      <BannerBackground src={background} alt="" />
      <Container>
        <BannerInner>
          <BannerTitle>{title}</BannerTitle>
          {subtitle && <BannerSubtitle>{subtitle}</BannerSubtitle>}
        </BannerInner>
      </Container>
    </BannerWrapper>
  )
}

export default BannerSimple
