import styled from 'styled-components'
import media from 'styles/media'

export const StyledContent = styled.div`
  h6,
  h5,
  h4,
  h3,
  h2,
  h1 {
    margin: 48px 0 24px 0;
    font-weight: 700;

    ${media.lg.max} {
      margin: 32px 0 16px 0;
    }
  }

  h1,
  h2 {
    font-size: 32px;
    line-height: 40px;

    ${media.lg.max} {
      font-size: 26px;
      line-height: 34px;
    }
  }

  h3 {
    font-size: 24px;
    line-height: 36px;

    ${media.lg.max} {
      font-size: 20px;
      line-height: 32px;
    }
  }

  h4,
  h5,
  h6 {
    font-size: 20px;
    line-height: 28px;

    ${media.lg.max} {
      font-size: 18px;
      line-height: 26px;
    }
  }

  p {
    margin: 0 0 32px 0;
    padding: 0;
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.white};

    ${media.lg.max} {
      margin: 0 0 16px 0;
      font-size: 18px;
      line-height: 26px;
    }

    & ~ ol,
    & ~ ul {
      margin: -16px 0 32px 0;
      ${media.lg.max} {
        margin: -8px 0 16px 0;
      }
    }
  }

  strong {
    font-weight: 700;
  }

  & ol,
  & ul {
    margin: 24px 0 32px 0;
    & ol,
    & ul {
      margin-top: 8px;
      margin-bottom: 0;
    }
    & li {
      position: relative;
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 12px;
      color: ${({ theme }) => theme.colors.white};

      ${media.lg.max} {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 8px;
      }

      &:last-child {
        margin: 0;
      }
    }
  }

  & ul {
    padding: 0;
    & li {
      padding: 0 0 0 16px;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 12px;
        display: block;
        background-color: ${({ theme }) => theme.colors.white};
        width: 6px;
        height: 6px;
        border-radius: 6px;

        ${media.lg.max} {
          top: 10px;
        }
      }
    }
  }
  & ol {
    padding: 0 24px;

    list-style-type: decimal;
  }

  img {
    margin: 32px 0;
    padding: 0;
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  blockquote {
    margin: 48px 0;
    padding: 32px;
    border: 2px solid ${({ theme }) => theme.colors.gray80};

    ${media.lg.max} {
      margin: 32px 0;
      padding: 24px;
    }

    p {
      margin: 0;
      padding: 0;
      font-size: 20px;
      line-height: 30px;

      ${media.lg.max} {
        font-size: 18px;
        line-height: 26px;
      }
    }
  }

  & > *:first-child {
    margin-top: 0 !important;
  }
  & > *:last-child {
    margin-bottom: 0 !important;
  }
`
