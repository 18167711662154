import styled, { css } from 'styled-components'
import { LazyImage } from 'components/atoms/Image'

import { IGatsbyImageData } from 'gatsby-plugin-image'
import media from 'styles/media'

type Props = {
  background: IGatsbyImageData
  high?: boolean
  align?: string
}

export const BannerInner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 800px;
  margin: 0 auto;
  z-index: 2;

  ${media.lg.max} {
    padding: 0 32px 0 0;
  }
`

export const BannerTitle = styled.h1`
  font-size: 50px;
  line-height: 62px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;

  ${media.lg.max} {
    font-size: 35px;
    line-height: 43px;
  }
`

export const BannerWrapper = styled.section<{
  high: Props['high']
  align: Props['align']
}>`
  position: relative;
  height: ${({ high }) => (high ? '960px' : '730px')};
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  ${media.lg.max} {
    max-height: unset;
    height: calc(100vh - 80px);
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0 0 64px 0;

    ${BannerInner} {
      align-items: flex-start;
      margin: 0;
    }

    ${BannerTitle} {
      text-align: left;
    }
  }

  ${({ align }) =>
    align === 'left' &&
    css`
      align-items: flex-start;

      ${BannerInner} {
        align-items: flex-start;
        margin: 0;
      }

      ${BannerTitle} {
        text-align: left;
      }
    `}
`

export const BannerBackground = styled(LazyImage)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      #000 0%,
      rgba(0, 0, 0, 0.1) 20%,
      rgba(0, 0, 0, 0.1) 80%,
      #000 100%
    );
    z-index: 1;
  }
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const BannerSubtitle = styled.p`
  position: relative;
  padding: 48px 0 0 0;
  margin: 36px 0 0 0;
  font-size: 25px;
  line-height: 33px;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 124px;
    height: 3px;
    background-color: ${({ theme }) => theme.colors.primary50};
    z-index: 2;
  }

  ${media.lg.max} {
    padding: 32px 0 0 0;
    margin: 24px 0 0 0;
    font-size: 22px;
    line-height: 30px;
    &:before {
      left: 0;
      transform: none;
      width: 64px;
    }
  }
`
