import { css } from 'styled-components'
import media from 'styles/media'

export const ButtonPrimary = css<{ small?: boolean; contrast?: boolean }>`
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 0;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};
  border: 0;
  background-color: transparent;
  border-radius: 0;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  cursor: pointer;
  transition: background-color 300ms ease-in-out, color 300ms ease-in-out,
    border-color 300ms ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.colors.primary50};
  }

  ${({ small }) =>
    small &&
    css`
      font-size: 16px;
      line-height: 24px;
    `}

  ${media.lg.max} {
    font-size: 16px;
    line-height: 24px;
  }
`

export const ButtonPrimaryContrast = css`
  ${ButtonPrimary}

  color: ${({ theme }) => theme.colors.black};
  &:before {
    background-color: ${({ theme }) => theme.colors.black};
  }
`
