import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { graphql, PageProps, Link } from 'gatsby'
import styled from 'styled-components'

import Seo from 'components/atoms/Seo'
import Header from 'components/organisms/Header/Header'
import Sidenav from 'components/organisms/Sidenav'
import Footer from 'components/organisms/Footer'
import BannerSimple from 'components/organisms/Banner/BannerSimple'
import Container from 'components/atoms/Container'
import StyledContent from 'components/atoms/StyledContent'
import { ButtonPrimary } from 'components/atoms/Button'
import Cookies from 'components/organisms/Cookies'

import { LazyImage } from 'components/atoms/Image'

import useBreakpoint from 'hooks/useBreakpoint'

import media from 'styles/media'

const Wrapper = styled.div`
  padding: 105px 0 0 0;

  ${media.lg.max} {
    padding: 80px 0 0 0;
  }
`

const PostContent = styled.section`
  margin: 96px 0;

  ${media.lg.max} {
    margin: 64px 0;
  }
`

const PostContentInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 800px;
`
const PostContentFooter = styled.div`
  margin: 96px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.lg.max} {
    margin: 54px 0 0 0;
  }
`

const PostContentCta = styled(Link)`
  ${ButtonPrimary}
`

const LatestPosts = styled.section`
  padding: 112px 0;
  border-top: 1px solid ${({ theme }) => theme.colors.gray80};

  ${media.lg.max} {
    padding: 64px 0;
  }
`

const LatestPostsInner = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 72px;
  grid-row-gap: 48px;
  width: 100%;
  margin: 0 auto;

  ${media.lg.max} {
    grid-template-columns: repeat(1, 1fr);
  }
`

const LatestPost = styled(Link)`
  position: relative;
  opacity: 1;
  transition: opacity 300ms ease-in-out;
  &:hover {
    opacity: 0.72;
  }
`

const LatestPostThumbnail = styled(LazyImage)`
  margin: 0 0 32px 0;
  height: 280px;
`

const LatestPostDate = styled.div`
  margin: 0 0 12px 0;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.white};
`

const LatestPostTitle = styled.div`
  font-size: 25px;
  line-height: 31px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};

  ${media.lg.max} {
    font-size: 24px;
    line-height: 32px;
    text-align: left;
  }
`

const LatestPostExcerpt = styled.div`
  margin: 12px 0 0 0;

  p {
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.white};
  }
`

const LayoutBlogPost: React.FC<PageProps<Queries.BlogPostQuery>> = ({
  data,
}) => {
  const POST = data?.POST!
  const LATEST_POSTS = data?.LATEST_POSTS?.edges!
  const SEO = data?.POST?.seo!

  if (!POST || !SEO || !LATEST_POSTS)
    throw new Error("CMS data didn't load properly")

  const [sidenavVisible, setSidenavVisible] = useState(false)

  const toggleSidenav = () => {
    setSidenavVisible(!sidenavVisible)
  }

  const { lg } = useBreakpoint()

  return (
    <Wrapper>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-T51CLP03RG"
        />
        <script>{`window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-T51CLP03RG');`}</script>
      </Helmet>
      <Seo
        title={SEO?.title!}
        description={SEO?.metaDesc!}
        ogTitle={SEO?.opengraphTitle!}
        ogDescription={SEO?.opengraphDescription!}
        ogImage={SEO?.opengraphImage?.sourceUrl!}
        twitterTitle={SEO?.twitterTitle}
        twitterDescription={SEO?.twitterDescription}
        twitterImage={SEO?.twitterImage?.sourceUrl}
      />
      <Header toggleSidenav={toggleSidenav} />
      {!lg && <Sidenav visible={sidenavVisible} />}
      <BannerSimple
        title={POST?.title!}
        subtitle={POST?.date!}
        background={
          lg
            ? POST?.Post?.bannerBg?.localFile?.childImageSharp?.gatsbyImageData!
            : POST?.Post?.bannerBgMobile?.localFile?.childImageSharp
                ?.gatsbyImageData!
        }
      />
      <PostContent>
        <Container>
          <PostContentInner>
            <StyledContent
              dangerouslySetInnerHTML={{
                __html: POST?.content!.replace('<h1>', '<h2>').replace(
                  '</h1>',
                  '</h2>'
                ),
              }}
            />
            <PostContentFooter>
              <PostContentCta to="/strefa-wiedzy">
                Wróć do listy wpisów
              </PostContentCta>
            </PostContentFooter>
          </PostContentInner>
        </Container>
      </PostContent>
      {LATEST_POSTS && (
        <LatestPosts>
          <Container>
            <LatestPostsInner>
              {LATEST_POSTS.map((post) => (
                <LatestPost to={post?.node?.uri!}>
                  <LatestPostThumbnail
                    src={
                      post?.node?.featuredImage?.node?.localFile
                        ?.childImageSharp!
                    }
                    alt={`Zdjęcie ${post?.node?.title!}`}
                  />
                  <LatestPostDate>{post?.node?.date!}</LatestPostDate>
                  <LatestPostTitle>{post?.node?.title!}</LatestPostTitle>
                  <LatestPostExcerpt
                    dangerouslySetInnerHTML={{ __html: post?.node?.excerpt! }}
                  />
                </LatestPost>
              ))}
            </LatestPostsInner>
          </Container>
        </LatestPosts>
      )}
      <Footer />
      <Cookies />
    </Wrapper>
  )
}

export default LayoutBlogPost

export const query = graphql`
  query BlogPost($slug: String) {
    POST: wpPost(slug: { eq: $slug }) {
      title
      slug
      date(formatString: "DD.MM.Y")
      content
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 2000)
            }
          }
        }
      }
      Post {
        bannerBg {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 2000)
            }
          }
        }
        bannerBgMobile {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 2000)
            }
          }
        }
      }
      seo {
        title
        title
        metaDesc
        opengraphDescription
        opengraphTitle
        opengraphImage {
          sourceUrl
        }
        twitterTitle
        twitterDescription
        twitterImage {
          sourceUrl
        }
      }
    }
    LATEST_POSTS: allWpPost(limit: 3, filter: { slug: { ne: $slug } }) {
      edges {
        node {
          title
          uri
          date(formatString: "DD.MM.Y")
          excerpt
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 2000)
                }
              }
            }
          }
        }
      }
    }
  }
`
